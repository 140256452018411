/**
 * Utility functions.
 */

/**
 * Get value of an accessible cookie (i.e., not http-only).
 * @param cookieName
 * @returns the value of the cookie if found, or `undefined` if not found.
 */
export function getCookieValue(cookieName) {
  return document.cookie
    .match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)")
    ?.pop();
}

export function getSessionId() {
  const olbSessionIdFromSessionStorage = sessionStorage.getItem("sessionId");
  const olbSessionIdFromCookie = getCookieValue("sessionId");

  return (
    olbSessionIdFromSessionStorage ||
    olbSessionIdFromCookie ||
    crypto.randomUUID()
  );
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Wait for a global variable to be defined.
 * @param variableName
 * @param maxAttempts default: 3
 * @param retryDelayMs default: 1000
 * @returns
 */
export async function waitForGlobalVariable(
  variableName: string,
  maxAttempts = 3,
  retryDelayMs = 1000,
) {
  let attempts = 0;

  while (attempts <= maxAttempts) {
    attempts++;
    if (typeof (window as any)[variableName] !== "undefined") {
      return;
    }

    if (attempts === maxAttempts) {
      throw new Error(
        `Retry limit reached waiting for global variable: ${variableName}`,
      );
    }

    await sleep(retryDelayMs);
  }
}

/**
 * Wait for a "complete function" that returns a boolean result to be true.
 * @param cbFunctionWithReturn function with no parameters that returns certain value
 * @param maxAttempts default: 3
 * @param retryDelayMs default: 1000
 * @returns
 */
export async function waitForCompleteFunction(
  cbFunctionWithReturn: () => any,
  maxAttempts = 3,
  retryDelayMs = 1000,
) {
  let attempts = 0;

  while (attempts <= maxAttempts) {
    attempts++;
    const val = cbFunctionWithReturn();
    if (Boolean(val)) {
      return val;
    }

    if (attempts === maxAttempts) {
      throw new Error(`Retry limit reached waiting for function`);
    }

    await sleep(retryDelayMs);
  }
}
