import { datadogRum } from "@datadog/browser-rum";
import { Environment } from "@olb/olb-mfe-common";
import { checkActivityFunctions, unregisterApplication } from "single-spa";
import { criticalMfes } from "./olb-root.constants";

// TODO Move this away from globals and into @olb/olb-mfe-common
interface CitizensOlbGlobals {
  environment: Environment;
  init: {
    status: "LOADING" | "SUCCESS" | "ERROR";
    activeMfes: string[];
    readyMfes: string[];
  };
}

declare global {
  var citizensOlbGlobals: CitizensOlbGlobals;
}

// After this initial `window` reference, `citizenOlbGlobals` can be referenced w/o `window` later on
window.citizensOlbGlobals = {
  environment: Environment.environmentConfig.environmentName,
  init: {
    status: "LOADING",
    activeMfes: [],
    readyMfes: [],
  },
};

let activeCriticalMfes = [];

// Start OLB initialization
export function initOlbRootLoading() {
  // Setup event listeners
  // Before first mount (e.g., first page load), determine the active MFEs and which ones are "critical"
  window.addEventListener("single-spa:before-first-mount", () => {
    citizensOlbGlobals.init.activeMfes = checkActivityFunctions(
      window.location,
    );
    activeCriticalMfes = citizensOlbGlobals.init.activeMfes.filter((mfe) =>
      criticalMfes.includes(mfe),
    );
  });

  // Listen for specific MFEs to be ready
  window.addEventListener("citizens:olb:mfe-ready", (event: CustomEvent) => {
    // Add to list of ready MFEs if not already present
    if (!citizensOlbGlobals.init.readyMfes.includes(event.detail.mfeName))
      citizensOlbGlobals.init.readyMfes.push(event.detail.mfeName);

    // Determine if OLB is ready
    if (
      // Only applies if OLB is still in a loading state
      citizensOlbGlobals.init.status === "LOADING" &&
      // Every active critical MFE should be ready
      activeCriticalMfes.every((mfe) =>
        citizensOlbGlobals.init.readyMfes.includes(mfe),
      )
    ) {
      onOlbReady();
    }
  });
}

// Listen for specific MFE ready errors
window.addEventListener(
  "citizens:olb:mfe-ready-error",
  (event: CustomEvent) => {
    onOlbError(`citizens:olb:mfe-ready-error for ${event.detail.mfeName}`);
  },
);

// Handler for when OLB is ready to display
export function onOlbReady() {
  citizensOlbGlobals.init.status = "SUCCESS";
  console.info("OLB initialization complete");

  // Custom RUM timing to track when OLB init is complete
  // See https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#add-your-own-performance-timing
  datadogRum.addTiming("citizens_olb_initialization_complete");

  // Display the main app area
  document
    .getElementById("single-spa-layout-main")
    .classList.remove("olb-u-display__none");

  // Remove the initialization overlay once it is hidden / its opacity transition ends
  document
    .querySelector(".olb-c-olbRootInit")
    .addEventListener("transitionend", (event) => {
      (event.target as HTMLElement).remove();
    });

  // Remove initialization class to begin transition for removal
  document
    .querySelector(".olb-c-olbRootInit")
    .classList.remove("olb-c-olbRootInit--initializing");

  // Clear interval used for loading message rotation
  clearInterval((window as any).olbLoadingMessageInterval);
}

// Handler for when OLB initialization has failed
export function onOlbError(errorMessage: string) {
  citizensOlbGlobals.init.status = "ERROR";
  console.error("OLB initialization failed:", errorMessage);

  document
    .querySelector(".olb-c-olbRootInit__loading")
    .classList.add("olb-u-display__none");
  document
    .querySelector(".olb-c-olbRootInit__error")
    .classList.remove("olb-u-display__none");

  // Unregister all active MFEs
  // TODO: Sometimes MFEs still end up loading, should stop all activity if we can
  citizensOlbGlobals.init.activeMfes.forEach((activeMfe) => {
    unregisterApplication(activeMfe);
  });
}
