import { Environment } from "@olb/olb-mfe-common";
import { waitForCompleteFunction, waitForGlobalVariable } from "./utils";

export function initAnalytics(sessionId: string) {
  // Add analytics script based on environment
  const script = document.createElement("script");
  script.src = Environment.environmentConfig.analyticsScriptUrl;
  document.head.appendChild(script);

  // Global variables used for analytics purposes
  let device,
    myPosition = "";
  if (screen.width < 768) {
    device = "mobi";
  } else if (screen.width >= 768 && screen.width <= 1024) {
    device = "tablet";
  } else {
    device = "desk";
  }

  global.CBDL = global.CBDL || {};
  global.CBDL.pgInfo = global.CBDL.pageInfo || {};
  global.CBDL.pgInfo = {
    // Common/base properties
    cbcountry: "us",
    cblanguage: "eng",
    cbwebidentifier: "servicing_web",
    cbdevice: device,
    cbbrand: "cb",
    cbproduct: "cbolb",
    cbtype: "", // Customer type
    cbhsbcflag: "", // HSBC customer flag
    cbCisKey: "",
    cbsessionId: sessionId,
    cbauthenticated: "",
    // View/activity-specific properties
    cbpgType: "",
    cbpgFunction: "",
    cbpgSubFunction: "",
    cbpgName: "view",
    cbaccType: "",
    cbaction: "view",
    cbtransferType: "",
    cbdeliveryType: "",
    cbotpUsed: "",
    cberrorId: "",
    cberrorDescription: "",
    cbssoAccounts: "",
  };
}

export function errorPageAnalytics(err) {
  global.CBDL = global.CBDL || {};
  global.CBDL.pgInfo = global.CBDL.pgInfo || {};
  global.CBDL.pgInfo = {
    ...global.CBDL.pgInfo,
    cbpgType: "error",
    cbpgFunction: "mfeerror",
    cberrorId: err?.appOrParcelName || "",
    cberrorDescription: err?.message || "",
    cbpgName: "error",
  };

  // Trigger error analytics event with retries
  waitForAnalytics().then(() => triggerAnalytics());
}

const triggerAnalytics = (eventName = "OLB_Pageload") => {
  if (isAnalyticsLoaded()) global["window"]["_satellite"].track(eventName);
  else
    console.error(
      `citizens:olb:error | olb-root | Failed to track event ${eventName}`,
    );
};

export function setGlassboxBreadcrumb(name: string, value: string) {
  return waitForGlobalVariable("_detector", 10, 1000)
    .then(() => {
      (window as any)._detector.triggerCustomEvent(name, value);
    })
    .catch((error) => {
      console.error("Failed to set Glassbox breadcrumb", name, error);
    });
}

export const isAnalyticsLoaded = () => {
  return global["window"] && global["window"]["_satellite"];
};

export const waitForAnalytics = () => {
  return waitForCompleteFunction(isAnalyticsLoaded).catch((error) => {
    console.error("Analytics still not loaded after retries", error);
  });
};
