import { datadogRum } from "@datadog/browser-rum";
import { Environment } from "@olb/olb-mfe-common";
import * as LDClient from "launchdarkly-js-client-sdk";
import { waitForCompleteFunction } from "./utils";

// LDClient instance is global for usage in the browser (e.g., by an Angular library).
declare global {
  var ldClient: LDClient.LDClient;
}

// Variables used to communicate if LD is ready
let ldClientReady = false;
let ldContextReady = false;

export function initLaunchDarkly() {
  const defaultUserContext =
    Environment.environmentConfig.launchDarklyDefaultUserContext;

  /**
   * Immediately start LaunchDarkly initialization.
   * LDClient instance should be global for usage in the browser (e.g., by an Angular library).
   */
  global.ldClient = LDClient.initialize(
    Environment.environmentConfig.launchDarklyClientSideId,
    // Default user context
    { kind: "user", key: defaultUserContext.key },
    // Hash is required if LD is in "secure mode" - see https://docs.launchdarkly.com/sdk/features/secure-mode
    { hash: defaultUserContext.hash, streaming: true },
  );

  // When the LD client is ready, set our variable
  ldClient.waitUntilReady().then(() => {
    ldClientReady = true;
  });

  // Attempt to identify user with context
  identifyUser();
}

async function identifyUser() {
  // If CIS key is available (e.g., user is logged in), initiate request for its LD context key hash
  const inAuthRoute = window.location.href.includes("/olb-root/home");
  const cisKey = sessionStorage.getItem("cisKey");
  if (inAuthRoute && cisKey) {
    try {
      const userContextHashRequest = await fetch(
        "/processapi/feature-entitlements-api/v1.0/double-hash-cis",
      );

      const userContextHashResponseJson = await userContextHashRequest.json();

      if (!userContextHashRequest.ok)
        throw new Error(
          `${userContextHashRequest.status}: ${JSON.stringify(
            userContextHashResponseJson,
          )}`,
        );

      await ldClient.identify(
        { kind: "user", key: userContextHashResponseJson.singleHashedCis },
        userContextHashResponseJson.doubleHashedCis,
      );

      datadogRum.addTiming("citizens_olb_launchdarkly_user_identify_complete");
    } catch (error) {
      console.error("LaunchDarkly user identify failed:", error);
      datadogRum.addTiming("citizens_olb_launchdarkly_user_identify_failed");
    }
  }

  // Context is "ready" at this point - has either completed or failed
  ldContextReady = true;
}

export function waitForLaunchDarkly() {
  return waitForCompleteFunction(isLaunchDarklyReady).catch((error) => {
    console.error("LaunchDarkly still not ready after retries", error);
  });
}

export function isLaunchDarklyReady() {
  return ldClientReady && ldContextReady;
}
