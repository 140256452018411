import "@angular/localize/init"; // see https://single-spa.js.org/docs/ecosystem-angular/#polyfills
import {
  addErrorHandler,
  getAppStatus,
  registerApplication,
  start,
} from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import {
  errorPageAnalytics,
  initAnalytics,
  waitForAnalytics,
} from "./analytics";
import { initDatadogRum } from "./datadog";
import { initLaunchDarkly, waitForLaunchDarkly } from "./launch-darkly";
import microfrontendLayout from "./microfrontend-layout.html";
import { initOlbRootLoading, onOlbError } from "./olb-root-loading";
import { criticalMfes } from "./olb-root.constants";
// Import styles for webpack to process
import "./styles/styles.scss";
import { getSessionId } from "./utils";

const sessionId = getSessionId();

// Initialize OLB root items
initDatadogRum(sessionId);
initAnalytics(sessionId);
initLaunchDarkly();
initOlbRootLoading();

const data = {
  props: {},
  loaders: {
    defaultLoader: `<div class="olb-c-defaultLoader olb-c-olbRootInit__loaderMargin"><span class="cbds-c-spinner cbds-u-margin__auto"></span><p class="olb-c-loadingDefault__text">Loading...</p></div>`,
  },
  errors: {
    defaultError: `<div class="olb-c-defaultError"><p class="olb-c-loadingError__text">Sorry, an error was encountered. Please try reloading the page.</p></div>`,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({
  routes,
  applications,
});

applications.forEach(registerApplication);

layoutEngine.activate();

// Wait for critical items before starting up
Promise.allSettled([waitForAnalytics(), waitForLaunchDarkly()]).then(() => {
  start();
});

addErrorHandler((err) => {
  // For now will always remove the app from SystemJS so that it will try re-loading it later.
  // Added a console log here to see what statuses are returned during error scenarios.
  // TODO: May want to revisit this in the future.
  console.error(
    `addErrorHandler, ${err.appOrParcelName} has app status:
    ${getAppStatus(err.appOrParcelName)}`,
  );

  if (
    criticalMfes.includes(err.appOrParcelName) &&
    (getAppStatus(err.appOrParcelName) == "LOAD_ERROR" ||
      getAppStatus(err.appOrParcelName) == "SKIP_BECAUSE_BROKEN")
  ) {
    errorPageAnalytics(err);
    onOlbError(err.message);
  }
  System.delete(System.resolve(err.appOrParcelName));
});
